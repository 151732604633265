export const faqs = [
    {
      title: 'Sobre a escolha do plano e o uso do software',
      text: ['Aqui, você encontra informações que vão ajudá-lo a começar a usar o software (escolha do plano, primeiros passos, formas de pagamento, promoções, etc.).']
    },
    {
      title: 'Sobre o conteúdo do software (como ele pode ajudá-lo)',
      text: ['Aqui, você encontra informações que ajudam a entender melhor os recursos e como eles ajudam você a fazer uma melhor gestão.']
    },
    {
      title: 'Esse software é útil a quem/que tipo de profissional?',
      text: [`Ele é útil a todo profissional que gere serviços ou contratos.`,
      `Os gestores de serviços podem ser Supervisores, Coordenadores, Gerentes, Diretores ou VP de Facilities (ou o nome que essa atividade receba em sua empresa) ou de Properties.`,
      `Os gestores de contratos podem ser os Supervisores, Coordenadores, Gerentes, Diretores ou VP de contratos de empresas prestadoras de serviços de todas (ou de cada uma) as disciplinas do segmento de Facilities (Manutenção Predial, Limpeza, Segurança, Gerenciamento, entre muitas outras).` ]
    },
    {
      title: 'Qual a diferença entre os planos? Gostaria de uma orientação para fazer a escolha.',
      text: ['O <strong>Plano Starter</strong> pode apoiar seu início na prática da gestão de Facilities/Properties com o apoio de uma ferramenta estratégica. Nele, você terá uma visão de todos os indicadores que quiser. Você tem duas possibilidades aqui. A primeira é inserir os indicadores que você tiver/quiser e a segunda é escolher quantos quiser do menu que oferecemos. Você terá acesso ao MPI (Management Performance Indicator) que é um número que traduz todo o desempenho de sua gestão. Com ele, você compara todos os seus prédios ou contratos.',
        'No Plano Pro, ajudamos você a definir quais são os melhores indicadores para a sua operação de Facilities, Properties ou Gestão de Contratos com base em nossa experiência de mais de 25 anos como gestores de Facilities, Properties e de Contratos. Você terá, além do MPI, o OPI (Operational Performance Indicator). Ele traduz sua performance operacional em um só número. Da mesma forma como no Plano Starter, você terá seus indicadores em Dashboards, verá ofensores automaticamente, terá gráficos de cada indicador.',
        'No Plano Premium, você terá todas as funcionalidades do Plano Pro e mais análise de dados (analytics) e insights de nossa equipe especializada para acelerar ainda mais as suas melhorias e otimizações.'
        ]
    },
    {
      title: 'Sobre a escolha do plano e o uso do software',
      text: ['Aqui, você encontra informações que vão ajudá-lo a começar a usar o software (escolha do plano, primeiros passos, formas de pagamento, promoções, etc.).' ]
    },
    {
      title: 'Sobre a escolha do plano e o uso do software',
      text: ['Aqui, você encontra informações que vão ajudá-lo a começar a usar o software (escolha do plano, primeiros passos, formas de pagamento, promoções, etc.).' ]
    },
    {
      title: 'Sobre a escolha do plano e o uso do software',
      text: ['Aqui, você encontra informações que vão ajudá-lo a começar a usar o software (escolha do plano, primeiros passos, formas de pagamento, promoções, etc.).' ]
    },
    {
      title: 'Sobre a escolha do plano e o uso do software',
      text: ['Aqui, você encontra informações que vão ajudá-lo a começar a usar o software (escolha do plano, primeiros passos, formas de pagamento, promoções, etc.).' ]
    },
    {
      title: 'Sobre a escolha do plano e o uso do software',
      text: ['Aqui, você encontra informações que vão ajudá-lo a começar a usar o software (escolha do plano, primeiros passos, formas de pagamento, promoções, etc.).' ]
    },
    {
      title: 'Sobre a escolha do plano e o uso do software',
      text: ['Aqui, você encontra informações que vão ajudá-lo a começar a usar o software (escolha do plano, primeiros passos, formas de pagamento, promoções, etc.).' ]
    },
    {
      title: 'Sobre a escolha do plano e o uso do software',
      text: ['Aqui, você encontra informações que vão ajudá-lo a começar a usar o software (escolha do plano, primeiros passos, formas de pagamento, promoções, etc.).' ]
    },
    {
      title: 'Sobre a escolha do plano e o uso do software',
      text: ['Aqui, você encontra informações que vão ajudá-lo a começar a usar o software (escolha do plano, primeiros passos, formas de pagamento, promoções, etc.).' ]
    },
  ]
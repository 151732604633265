import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Site from './Site';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBfWcVGm7EpVKH4c4p89-Fi3eTY95gKMk8",
  authDomain: "aro-tech-io.firebaseapp.com",
  projectId: "aro-tech-io",
  storageBucket: "aro-tech-io.appspot.com",
  messagingSenderId: "666851090664",
  appId: "1:666851090664:web:caad885b0c288b41e2295d",
  measurementId: "G-XHW5KNV14T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
analytics.app.automaticDataCollectionEnabled = true

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Site />
  </React.StrictMode>
);
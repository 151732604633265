import { useEffect, useState } from 'react';
import './Site.css';
import Logo from './assets/images/Logo.png'
import HomeImage from './assets/images/ImagemHome01Mobile02.png'
import DashboardImage from './assets/images/BackgroundSection3.png'
import OfensoresImage from './assets/images/ImagemSection04Mobile02.png'
import PredicaoImage from './assets/images/ImagemSection05.png'
import CenariosImage from './assets/images/ImagemSection06Mobile03.png'
import LucianoImage from './assets/images/Luciano2.png'
import AlvaroImage from './assets/images/Alvaro.png'
import { faqs } from './data/Faqs';
import { PlusIcon } from './assets/icons/plus.icon';
import { MinusIcon } from './assets/icons/minus.icon';
import { ChevronDownIcon } from './assets/icons/chevron-down.icon';
import { FacebookIcon } from './assets/icons/facebook.icon';
import { LinkedinIcon } from './assets/icons/linkedin.icon';
import { InstagramIcon } from './assets/icons/instagram.icon';
import { BarsIcon } from './assets/icons/bars.icon';
import { CloseIcon } from './assets/icons/close.icon';

const loginPage = 'https://arotech.app'

function Site() {
  const [openMenu, setOpenMenu] = useState(false)

  useEffect(()=>{
    if(window.location.hash === '#faq') requestAnimationFrame(() => goTo('faq'))
  }, [])

  function handleMenuClick(){
    setOpenMenu(!openMenu)
  }

  function goTo(local: 'recursos' | 'planos' | 'faq'){
    setOpenMenu(false)
    const element = document.getElementById(local)
    if(!element) return
    element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  function handleToTop(){
    document.documentElement.scrollTop = 0; // Para navegadores modernos
    document.body.scrollTop = 0;
    document.body.scrollTo()
  }

  function sendEmail(){
    window.location.href = 'mailto:admin@arotech.app?subject=Solicitação%20de%20Informações%20-%20site&body=Olá%20Equipe%20ARO%20Tech,%0A%0AMeu%20nome%20é%0A%0AGostaria%20de%20solicitar%20informações%20sobre'
  }

  return (
    <div id='site' className="Site">
      <header>
        <div className='left'>
          <img className='logo' src={Logo} alt='Logo ARO Tech'/>
        </div>
        <div className='center'>
          <div className='menuItens'>
            <a onClick={() => {goTo('recursos')}}>Recursos</a>
            <a onClick={() => {goTo('planos')}}>Planos</a>
            <a onClick={() => {goTo('faq')}}>FAQ</a>
            <a onClick={sendEmail}>Fale conosco</a>
          </div>
        </div>
        <div className='right'>
          <a className='loginButton' href={loginPage} target="_blank"><button >Fazer login</button></a>
          <div className='menuButton' onClick={handleMenuClick}>{ openMenu ? <CloseIcon /> : <BarsIcon /> }</div>
        </div>
      </header>
      {openMenu && <div className={`menu`}>
        <div className='menuItens'>
          <a onClick={() => {goTo('recursos')}}>Recursos</a>
          <a onClick={() => {goTo('planos')}}>Planos</a>
          <a onClick={() => {goTo('faq')}}>FAQ</a>
          <a onClick={sendEmail}>Fale conosco</a>
          <a href={loginPage} target="_blank" rel="noreferrer"><button >Fazer login</button></a>
        </div>
      </div>
      }
      <main>
        <section id="top">
          <div className='container'>
            <h1>Software de Gestão de Facilities, Properties e Contratos</h1>
            <p>Você que é gestor de Facilities, Properties ou Prestadores de Serviços chegou a solução para consolidar todos os seus dados e transformá-los em informação de qualidade para tomar as melhores decisões</p>
            <button onClick={sendEmail}>Experimente agora</button>
          </div>
          <img src={HomeImage} alt='Home page do software Aro Tech' />
        </section>
        <section id="recursos" >
          <div className='container'>
            <h2>Indicadores-chave para melhor gerir custos, riscos e experiências</h2>
            <p>Você tem, em uma tela, todas as informações de que precisa. Os indicadores são os que você escolher e nós podemos ajudá-lo nessa definição.</p>
          </div>
            <div className='indicadores'>
              <div className='card'>
                <div className='cardHeader'>
                  <h3>Facilities</h3>
                </div>
                <div className='cardContent'>
                  <ul>
                    <li>Custos / m2</li>
                    <li>Custos /pessoa</li>
                    <li>Consumo /pessoa</li>
                    <li>Índice de Satisfação (NPS)</li>
                    <li>Índice ESG</li>
                    <li>Índice de Segurança Patrimonial</li>
                    <li>Índice de Manutenção</li>
                    <li>Índice de Limpeza</li>
                    <li>Taxa de Ocupação</li>
                    <li>Vencimento de Documentação Legal</li>
                    <li>Vencimento de Contratos</li>
                    <li>Custos de Aluguel</li>
                    <li>Custos de Condomínio</li>
                  </ul>
                </div>
              </div>
              <div className='card'>
                <div className='cardHeader' style={{backgroundColor: '#EC0561'}}>
                  <h3>Properties</h3>
                </div>
                <div className='cardContent'>
                  <ul>
                    <li>CDisponibilidade de Energia</li>
                    <li>CDisponibilidade de Elevadores</li>
                    <li>CAvaliação da Gerenciadora</li>
                    <li>CCustos / m2</li>
                    <li>CCustos /pessoa</li>
                    <li>CConsumo /pessoa</li>
                    <li>CÍndice de Satisfação (NPS)</li>
                    <li>CÍndice ESG</li>
                    <li>CTaxa de Ocupação</li>
                    <li>CVencimento de Documentação Legal</li>
                    <li>CVencimento de Contratos</li>
                  </ul>
                </div>
              </div>
              <div className='card'>
                <div className='cardHeader' style={{backgroundColor:'#ED2783'}}>
                  <h3>Prestadoras de serviços</h3>
                </div>
                <div className='cardContent'>
                  <ul>
                    <li>CQuadro de Pessoal</li>
                    <li>CBenefícios</li>
                    <li>CInsalubridade</li>
                    <li>CAbsenteísmo </li>
                    <li>CTurnover </li>
                    <li>CSLA / Descontos NF</li>
                    <li>CCustos de Produtos </li>
                    <li>CReceitas</li>
                    <li>CTicket Médio</li>
                    <li>CMargem Bruta</li>
                    <li>CSatisfação dos Clientes</li>
                    <li>CÍndice de Qualidade</li>
                    <li>CControle de Estoque</li>
                    <li>CDesempenho Logístico</li>
                    <li>CAtendimento de Normas</li>
                  </ul>
                </div>
              </div>
            </div>
        </section>
        <section id='blackBar'>
          <div className='blackBar'>
            <h3>Todas as informações de que você precisa para uma boa gestão de recursos</h3>
            <div className='separador'></div>
            <button onClick={sendEmail}>Experimente agora</button>
          </div>
        </section>
        <section className='dashboardSection'>
          <div className='dashboardContent'>
            <h2>Dashboards com seus indicadores de Facilities/Properties</h2>
            <p>O nosso dashboard oferece uma visão de todos os indicadores e você pode filtrar a visão comparativa que quiser (por período, prédio ou grupo de prédios, contrato ou grupo de contratos).</p>
            <p>E o mais interessante é que você tem todos os indicadores de gestão combinados em 1 só número (MPI) que traduz toda a sua performance em cada prédio ou contrato.</p>
            <p>Do lado da operação, também temos outro indicador exclusivo que traduz seu desempenho operacional em um só número (OPI).</p>
            <button onClick={sendEmail}>Experimente agora</button>
          </div>
          <img src={DashboardImage} alt='Página de dashboard do software Aro Tech'/>
        </section>
        <section>
          <svg className='ofensoresTop' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <defs>
            <linearGradient id="gradienteVertical" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: '#ED6114', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: '#ED6114', stopOpacity: 1 }} />
            </linearGradient>
            </defs>
            <path className="elementor-shape-fill" fill="url(#gradienteVertical)" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
            c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
            c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
          </svg>
          <div className='ofensores'>
            <h2>Os principais ofensores são exibidos automaticamente</h2>
            <p>Você ainda conta com gráficos e relatórios que permitem mergulhar nos números e entender melhor seu desempenho em cada indicador.</p>
            <img src={OfensoresImage} alt='Página de ofensores do software Aro Tech'/>
            <button onClick={sendEmail}>Experimente agora</button>
          </div>
        </section>
        <section className='predicao'>
          <div className='predicao'>
            <div className='content'>
              <h2>Predição e Insights</h2>
              <p>Nos próximos cinco anos, a integração de tecnologias de Inteligência Artificial (IA) na gestão de facilities se tornará uma prática padrão para muitas organizações. Isto é, a IA será usada para otimizar operações, identificar padrões de uso e desperdícios.</p>
            </div>
            <img src={PredicaoImage} alt='Imagem de uma rede optica'/>
          </div>
          <h4>Consulte-nos para saber mais</h4>
          <button onClick={sendEmail}>Experimente agora</button>
        </section>
        <svg className='cenariosTop' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
          <defs>
          <linearGradient id="gradienteVertical" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#252F5F', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#252F5F', stopOpacity: 1 }} />
          </linearGradient>
          </defs>
          <path className="elementor-shape-fill" fill="#252F5F" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
          c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
          c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
        </svg>
        <div className='cenariosPlanos'>
        <section className='cenarios'>
          <h2>Compare os cenários</h2>
          <img src={CenariosImage} alt='Comparativo de cenários. Cenário Típico versus com software ARO Tech' />
        </section>
        <section id="planos">
          <h2>Confira nossos planos</h2>
          <div className='planos'>
            <div className='plano card'>
              <h2>Starter</h2>
              <p>12x de</p>
              <h1 className='greenText'>350,00</h1>
              <p className='smallText'>no plano anual</p>
              <p className='subtextColor'>R$ 420/mês no plano Mensal</p>
              <div className='items'>
                <div className='item'>
                  <CheckIcon />
                  <p className='smallText itemText'>Os Indicadores que escolher do menu ARO Tech OU os seus indicadores (você mesmo cadastra) com múltiplos filtros</p>
                </div>
                <div className='item'>
                  <CheckIcon />
                  <p className='smallText itemText'>Nosso indicador exclusivo MPI (Management Performance Indicator)</p>
                </div>
              </div>
              <button style={{backgroundColor:'var(--green-color)'}}  onClick={sendEmail}>Primeiro mês grátis</button>
            </div>
            <div className='plano card'>
              <h2>Pro</h2>
              <p>12x de</p>
              <h1 className='greenText'>550,00</h1>
              <p className='smallText'>no plano anual</p>
              <p className='subtextColor'>R$ 670/mês no plano Mensal</p>
              <p className='smallText greenText marginTop20'>+ Implementação consultiva</p>
              {/* <p className='subtextColor'>de <del>R$ 9.700,00</del> por apenas</p> */}
              <p >R$ 9.700,00</p>
              <p className='subtextColor'>Pagamento único!</p>
              <div className='items'>
                <div className='item'>
                  <CheckIcon />
                  <p className='smallText itemText'>Os Indicadores que escolher do menu ARO Tech OU os seus indicadores (você mesmo cadastra) com múltiplos filtros</p>
                </div>
                <div className='item'>
                  <CheckIcon />
                  <p className='smallText itemText'>Nosso indicador exclusivo MPI (Management Performance Indicator)</p>
                </div>
                <div className='item'>
                  <CheckIcon />
                  <p className='smallText itemText'>Nossos indicadores exclusivos MPI (Management Performance Indicator) e OPI (Operational Performance Indicator)</p>
                </div>
              </div>
              <button onClick={sendEmail}>Experimente agora</button>
            </div>
            <div className='plano card'>
              <h2>Premium</h2>
              <h1 className='greenText'>A Consultar</h1>
              <div className='items'>
                <div className='item'>
                  <CheckIcon />
                  <p className='smallText itemText'>Os Indicadores que escolher do menu ARO Tech OU os seus indicadores (você mesmo cadastra) com múltiplos filtros</p>
                </div>
                <div className='item'>
                  <CheckIcon />
                  <p className='smallText itemText'>Relatórios de todos os indicadores</p>
                </div>
                <div className='item'>
                  <CheckIcon />
                  <p className='smallText itemText'>Nossos indicadores exclusivos MPI (Management Performance Indicator) e OPI (Operational Performance Indicator)</p>
                </div>
                <div className='item'>
                  <CheckIcon />
                  <p className='smallText itemText'>Predição e insights da Consultoria ARO</p>
                </div>
              </div>
              <button onClick={sendEmail} >Experimente agora</button>
            </div>
          </div>
        </section>
        </div>
        <section id='quemSomosNos'>
          <h2>Quem somos nós</h2>
          <div className='integrantes'>
            <div className='integrante'>
              <img src={LucianoImage} alt='Rosto Luciano Brunherotto'/>
              <h3>Luciano Brunherotto</h3>
              <p className='smallText'>Conselheiro e ex-presidente da ABRAFAC (Associação Brasileira de Facility Management, Property e Workplace), Embaixador de Facilities para o EuroFM no Brasil, é Engenheiro Civil com MBA em Gestão de Facilities. Atuou 25+ anos na gestão de Facilities em empresas como ABB, Natura, Santander, Hopi-Hari e Sodexo</p>
            </div>
            <div className='integrante'>
              <img src={AlvaroImage} alt='Rosto Álvaro Aguiar'/>
              <h3>Álvaro Aguiar</h3>
              <p className='smallText'>Cofundador e ex-diretor e conselheiro da ABRAFAC e da CoreNet Global, é Engenheiro Civil, com Mestrado em Arquitetura, MBA executivo, além de algumas pós-graduações. Atuou 25+ anos em Corporate Real Estate (Facilities, Projetos&Obras e Gestão de Imóveis) em empresas como Globo, Google e AC Camargo</p>
            </div>
          </div>
        </section>
        <section id="faq">
          <h2>FAQ</h2>
          <p>Criamos alguns grupos de temas sobre os quais você pode ter dúvidas.</p>
          <Faq list={faqs} />
        </section>
      </main>
      <footer>
        <div className='footerContent'>
        <section>
          <div className='blackBar'>
            <h3>Quer melhorar o desempenho da sua gestão de Facilities/Properties?</h3>
            <div className='separador'></div>
            <button onClick={sendEmail}>Fale com nosso time</button>
          </div>
        </section>
        <section id='footerLogo'>
          <img src={Logo} alt='Logo Aro Tech' />
        </section>
        <section className='content'>
          <div className='footerMenu'>
            <p onClick={() => {goTo('recursos')}}>Recursos</p>
            <p onClick={() => {goTo('planos')}}>Planos</p>
            <p onClick={() => {goTo('faq')}}>FAQ</p>
            <p onClick={sendEmail}>Fale Conosco</p>
          </div>
          <div className='separador' />
          <div className='contato'>
            <h4>Conecte-se Conosco</h4>
            <div className='icons'>
              <a href='https://www.facebook.com/arocorp/' target="_blank" rel="noreferrer"><FacebookIcon /></a>
              <a href='https://www.linkedin.com/company/arobr/' target="_blank" rel="noreferrer"><LinkedinIcon /></a>
              <a href='https://www.instagram.com/arofacilitiesmanagement' target="_blank" rel="noreferrer"><InstagramIcon /></a>
            </div>
          </div>
        </section>
        <section>
          <p className='subtextColor'>&copy; ARO. Todos os Direitos Reservados.</p>
        </section>
        </div>
      </footer>
      <div className='toTopButton' onClick={handleToTop}>
        <ChevronDownIcon />
      </div>
    </div>
  );
}

export default Site;

function CheckIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
    <path d="M3.1875 15.4062C2.76481 15.4062 2.35943 15.2383 2.06055 14.9395C1.76166 14.6406 1.59375 14.2352 1.59375 13.8125V3.1875C1.59375 2.76481 1.76166 2.35943 2.06055 2.06055C2.35943 1.76166 2.76481 1.59375 3.1875 1.59375H11.6875C11.8284 1.59375 11.9635 1.64972 12.0632 1.74935C12.1628 1.84898 12.2188 1.9841 12.2188 2.125C12.2188 2.2659 12.1628 2.40102 12.0632 2.50065C11.9635 2.60028 11.8284 2.65625 11.6875 2.65625H3.1875C3.0466 2.65625 2.91148 2.71222 2.81185 2.81185C2.71222 2.91148 2.65625 3.0466 2.65625 3.1875V13.8125C2.65625 13.9534 2.71222 14.0885 2.81185 14.1882C2.91148 14.2878 3.0466 14.3438 3.1875 14.3438H13.8125C13.9534 14.3438 14.0885 14.2878 14.1882 14.1882C14.2878 14.0885 14.3438 13.9534 14.3438 13.8125V8.5C14.3438 8.3591 14.3997 8.22398 14.4993 8.12435C14.599 8.02472 14.7341 7.96875 14.875 7.96875C15.0159 7.96875 15.151 8.02472 15.2507 8.12435C15.3503 8.22398 15.4062 8.3591 15.4062 8.5V13.8125C15.4062 14.2352 15.2383 14.6406 14.9395 14.9395C14.6406 15.2383 14.2352 15.4062 13.8125 15.4062H3.1875Z" fill="#76B72A">
    </path>
    <path d="M8.87612 11.0013L16.3136 3.56381C16.363 3.51441 16.4022 3.45578 16.4289 3.39124C16.4557 3.3267 16.4694 3.25754 16.4694 3.18768C16.4694 3.11783 16.4557 3.04866 16.4289 2.98413C16.4022 2.91959 16.363 2.86095 16.3136 2.81156C16.2642 2.76216 16.2056 2.72298 16.1411 2.69625C16.0765 2.66952 16.0074 2.65576 15.9375 2.65576C15.8676 2.65576 15.7985 2.66952 15.7339 2.69625C15.6694 2.72298 15.6108 2.76216 15.5614 2.81156L8.5 9.87399L5.68862 7.06156C5.63923 7.01216 5.58059 6.97298 5.51606 6.94625C5.45152 6.91952 5.38235 6.90576 5.3125 6.90576C5.24265 6.90576 5.17348 6.91952 5.10894 6.94625C5.04441 6.97298 4.98577 7.01216 4.93637 7.06156C4.88698 7.11095 4.8478 7.16959 4.82107 7.23412C4.79434 7.29866 4.78058 7.36783 4.78058 7.43768C4.78058 7.50753 4.79434 7.5767 4.82107 7.64124C4.8478 7.70578 4.88698 7.76441 4.93637 7.81381L8.12387 11.0013C8.17322 11.0508 8.23185 11.09 8.29639 11.1168C8.36093 11.1436 8.43012 11.1574 8.5 11.1574C8.56988 11.1574 8.63907 11.1436 8.70361 11.1168C8.76815 11.09 8.82678 11.0508 8.87612 11.0013Z" fill="#76B72A">
    </path>
  </svg>
}

type dropdownProps = {
  title: string,
  children: React.ReactNode,
  className?: string
}

function Dropdown({title, children, className}: dropdownProps){
  const [open, setOpen] = useState(false)
  function handleClick(){
    setOpen(!open)
  }
  return <div className={`dropdown ${className ?? ''}`}>
    <div className='button' onClick={handleClick}>
      {open? <MinusIcon color={open && 'var(--green-color)'} /> : <PlusIcon /> }
      <p className={`bold ${open && 'greenText'}`}>{title}</p>
    </div>
    <div className={`${open ? 'flex' : 'hide'} content`}>
      { children }
    </div>
  </div>
}

type FaqProps = {
  list: { title: string, text:string[] }[]
}

function Faq({list}: FaqProps){
  return <div className='faqs'>
    <Dropdown 
      title='Sobre a escolha do plano e o uso do software' 
    >
      <p>Aqui, você encontra informações que vão ajudá-lo a começar a usar o software (escolha do plano, primeiros passos, formas de pagamento, promoções, etc.).</p>
    </Dropdown>
    <Dropdown 
      title='Sobre o conteúdo do software (como ele pode ajudá-lo)' 
    >
      <p>Aqui, você encontra informações que ajudam a entender melhor os recursos e como eles ajudam você a fazer uma melhor gestão.</p>
    </Dropdown>
    <Dropdown 
      title='Esse software é útil a quem/que tipo de profissional?' 
    >
      <p>Ele é útil a todo profissional que gere serviços ou contratos.
      </p>
      <p>Os gestores de serviços podem ser Supervisores, Coordenadores, Gerentes, Diretores ou VP de Facilities (ou o nome que essa atividade receba em sua empresa) ou de Properties.
      </p>
      <p>Os gestores de contratos podem ser os Supervisores, Coordenadores, Gerentes, Diretores ou VP de contratos de empresas prestadoras de serviços de todas (ou de cada uma) as disciplinas do segmento de Facilities (Manutenção Predial, Limpeza, Segurança, Gerenciamento, entre muitas outras).
      </p>
    </Dropdown>
    <Dropdown 
      title='Qual a diferença entre os planos? Gostaria de uma orientação para fazer a escolha.' 
    >
      <p>
      O <strong>Plano Starter</strong> pode apoiar seu início na prática da gestão de Facilities/Properties com o apoio de uma ferramenta estratégica. Nele, você terá uma visão de todos os indicadores que quiser. Você tem duas possibilidades aqui. A primeira é inserir os indicadores que você tiver/quiser e a segunda é escolher quantos quiser do menu que oferecemos. Você terá acesso ao MPI (Management Performance Indicator) que é um número que traduz todo o desempenho de sua gestão. Com ele, você compara todos os seus prédios ou contratos.
      </p>
      <p>
      No <strong>Plano Pro</strong>, ajudamos você a definir quais são os melhores indicadores para a sua operação de Facilities, Properties ou Gestão de Contratos com base em nossa experiência de mais de 25 anos como gestores de Facilities, Properties e de Contratos. Você terá, além do MPI, o OPI (Operational Performance Indicator). Ele traduz sua performance operacional em um só número. Da mesma forma como no Plano Starter, você terá seus indicadores em Dashboards, verá ofensores automaticamente, terá gráficos de cada indicador.
      </p>
      <p>
      No <strong>Plano Premium</strong>, você terá todas as funcionalidades do Plano Pro e mais análise de dados (analytics) e insights de nossa equipe especializada para acelerar ainda mais as suas melhorias e otimizações.
    </p>
    </Dropdown>
    <Dropdown 
      title='Como faço para escolher meus indicadores (no Plano Starter)?' 
    >
      <p>Você deve clicar no botão “Primeiro mês grátis!” e, em seguida, fazer seu cadastro através de um formulário, dentro do mesmo você pode marcar quais indicadores te interessam.</p>
    </Dropdown>
    <Dropdown 
      title='Como funciona a alimentação de dados no sistema?' 
    >
      <p>Em todos os planos, você insere os dados necessários à geração dos seus indicadores, diretamente no software, usando a tela “Dados Cadastrais” do menu que está na lateral esquerda da tela. Se você preferir, podemos criar uma API para retirar dados automaticamente dos seus sistemas. Consulte-nos para saber mais sobre essa solução.</p>
    </Dropdown>
    <Dropdown 
      title='Quanto tempo dura a implementação consultiva do Plano Pro e no Premium?' 
    >
    <p>
      Nos dois planos, esse prazo fica em torno de 2 meses. Em duas reuniões, vamos definir juntos os indicadores ideais para você e, usando nossa metodologia, chegaremos à combinação ideal dos seus  indicadores para modelar o seu MPI (Management Performance Indicator) e seu OPI (Operational Performance Indicator). Depois, é só fazer os ajustes na programação.
    </p>
    </Dropdown>
    <Dropdown 
      title='Como o software ARO Tech pode me ajudar no dia a dia?' 
    >
      <p>
      Ele ajuda você a fazer gestão porque mostra os indicadores que interessam a você.
      </p>
      <p>
      Você pode ver todos eles de várias formas. No período que você escolher, pode ver os indicadores agrupados por prédio (ou contrato, se você for prestador de serviços) ou por grupos de prédios. Os grupos você mesmo pode formar. Você pode também escolher ver cada indicador separadamente em um gráfico do período que quiser.
      </p>
      <p>
      Você também tem todos os indicadores traduzidos em um só número que define a qualidade da sua gestão. Ele é o MPI (Management Performance Indicator). Você também vê em apenas um número a qualidade de sua operação. Ele é o OPI (Operational Performance Indicator).
      </p>
    </Dropdown>
    <Dropdown 
      title='O que é e o que faz o MPI?' 
    >
      <p>
      Ele é um número, de uma escala de 0% a 100%, que representa toda a qualidade da sua gestão. Ele é a combinação de todos os indicadores que você escolher (sejam aqueles escolhidos por você no Plano Starter ou os escolhidos com a nossa ajuda nos planos Pro ou Premium) e é gerado com o nosso know-how de mais de 25 anos como gestores de Facilities e Properties. 
      </p>
      <p>
      Ele mostra a qualidade da sua gestão de forma simples (em apenas um número) e, comparando o MPI de um site/prédio/contrato com outros, você pode identificar facilmente variações e desvios que vão ajudá-lo a tomar ações de melhoria.
      </p>
    </Dropdown>
    <Dropdown 
      title='O que é e o que faz o OPI?' 
    >
    <p>
    Ele é um número, de uma escala de 0% a 100%, que representa toda a qualidade da sua operação. Ele é composto pelos indicadores de natureza operacional que você escolher (sejam aqueles escolhidos por você no Plano Starter ou os escolhidos com a nossa ajuda nos planos Pro ou Premium) e é gerado com nosso know-how de mais de 25 anos como gestores de Facilities e Properties. 
    </p>
    <p>
    Ele mostra a qualidade da sua operação de forma simples (em apenas um número) e, comparando o OPI de um site/prédio/contrato com outros, você pode identificar facilmente variações e desvios que vão ajudá-lo a tomar ações de melhoria.
    </p>
    </Dropdown>
    <Dropdown 
      title='O que são ofensores e onde eu encontro eles?' 
    >
    <p>
    São os indicadores que ficaram mais longe de suas metas no período. São sempre 3 ofensores que indicam os assuntos que mais precisam de correções/ajustes para melhorar sua performance. 
    </p>
    <p>
    O software mostra os três automaticamente, ao lado do MPI (Management Performance Indicator) e do OPI (Operational Performance Indicator) na tela “Dashboard”.  
    </p>
    </Dropdown>
    <Dropdown 
      title='Qual é a diferença entre Facilities e Properties?' 
    >
    <p>
    Os gestores das duas áreas têm funções técnicas semelhantes. Ambos cuidam da manutenção e operação de edifícios (ambientes de trabalho). 
    </p>
    <p>
    O gestor de Facilities, está fazendo isso para apoiar a atividade de sua empresa e o de Properties está cuidando do principal ativo de uma empresa de ativos imobiliários, portanto cuidando de algo que faz parte do negócio. Essa diferença de enfoque faz com que a administração desses ativos imobiliários (edifícios) tenha premissas e prioridades diferentes.
    </p>
    <p>
    Como ilustração, um edifício comercial com diversos locatários tem um gestor de Properties (cuida das áreas comuns do edifícios) e diversos gestores de Facilities (cuidam das áreas privativas locadas pelas diversas empresas ocupantes do edifício).
    </p>
    </Dropdown>
  </div>
}